import React from 'react';
import { graphql } from 'gatsby';
import { IndexQuery } from '@types';
import { PageHome } from '@src/ui';
import { adaptKnowledges } from '@src/adapters/knowledge';
import { adaptParagraphs } from '@src/adapters/paragraph';
import { usePageList } from '@src/hooks/page-list';

interface Props {
  data: IndexQuery;
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const pages = usePageList();
  const paragraphsData = adaptParagraphs(data.paragraphs);
  const knowledgesPics = adaptKnowledges(data.knowledges);

  const currentPageData = pages.find(p => p.id === 'home');
  const presentationData = paragraphsData.find(e => e.id == 'presentation');
  const approcheData = paragraphsData.find(e => e.id == 'approche');

  const knowledgesData = [
    {
      id: 'osteopathie',
      title: 'Ostéopathie',
      link: '/osteopathie-animale/',
      image: knowledgesPics.find(k => k.id === 'osteopathie')?.image,
    },
    {
      id: 'phytotherapie',
      title: 'Phytothérapie',
      image: knowledgesPics.find(k => k.id === 'phytotherapie')?.image,
    },
    {
      id: 'homeopathie',
      title: 'Homéopathie',
      image: knowledgesPics.find(k => k.id === 'homeopathie')?.image,
    },
    {
      id: 'elixirs-floraux',
      title: 'Elixirs floraux',
      image: knowledgesPics.find(k => k.id === 'elixirs-floraux')?.image,
    },
    {
      id: 'mycotherapie',
      title: 'Mycothérapie',
      image: knowledgesPics.find(k => k.id === 'mycotherapie')?.image,
    },
    {
      id: 'gemmotherapie',
      title: 'Gemmothérapie',
      image: knowledgesPics.find(k => k.id === 'gemmotherapie')?.image,
    },
  ];

  return (
    <PageHome
      image={currentPageData?.image}
      presentationImage={presentationData?.image}
      approcheImage={approcheData?.image}
      knowledgesData={knowledgesData}
    />
  );
};

export const query = graphql`
  query Index {
    paragraphs: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "paragraph" } }
        frontmatter: { id: { in: ["approche", "presentation"] } }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 330, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    knowledges: allMarkdownRemark(
      filter: { fields: { collection: { eq: "knowledge" } } }
    ) {
      edges {
        node {
          frontmatter {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 180, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
