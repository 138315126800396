import {
  GatsbyImageSharpFluid_WithWebpFragment,
  MarkdownRemarkFrontmatter,
  KnowledgeData,
} from '@types';
import { adaptImage } from './image';

type KnowledgeFrontmatter =
  | (Pick<MarkdownRemarkFrontmatter, 'id'> & {
      image?:
        | {
            childImageSharp?:
              | {
                  fluid?:
                    | GatsbyImageSharpFluid_WithWebpFragment
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
    })
  | null
  | undefined;

export const adaptKnowledge = (
  data: {
    frontmatter?: KnowledgeFrontmatter;
  } | null,
): KnowledgeData | null => {
  if (data?.frontmatter?.id && data?.frontmatter?.image) {
    const pic = adaptImage(data.frontmatter.image);
    return pic
      ? {
          id: data.frontmatter.id,
          image: pic,
        }
      : null;
  }
  return null;
};

export const adaptKnowledges = (data: {
  edges: {
    node: {
      frontmatter?: KnowledgeFrontmatter;
    };
  }[];
}): KnowledgeData[] => {
  return data.edges
    .map(e => adaptKnowledge(e.node))
    .flatMap(e => (e !== null ? [e] : []));
};
